<template>
  <div class="error">404</div>
</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped lang="less">
.error {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cacaca;
  font-weight: bold;
  font-size: 92px;
}
</style>